
import { defineComponent } from "vue";
import PageHeader from "@/common/PageHeader.vue";
import { contentsService } from "@/services/contents.service";
import { StaticContactContent } from "@/models/static-contact.model";

export default defineComponent({
  name: "ContactApplicative",
  components: {PageHeader },
  data() {
    return {
      // eslint-disable-next-line no-undef
      page: undefined as StaticContactContent | undefined,
    };
  },
  mounted() {
    contentsService.findBySlug("contact").then((page) => {
      this.page = page as StaticContactContent;
    });
  },
});
